import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private shown = false;
  private disabled = false;
  private shown$: BehaviorSubject<boolean> = new BehaviorSubject(this.shown && !this.disabled);
  private disabled$: BehaviorSubject<boolean> = new BehaviorSubject(this.disabled);

  get isShown$(): Observable<boolean> {
    return this.shown$.asObservable();
  }

  get isDisabled$(): Observable<boolean> {
    return this.disabled$.asObservable();
  }

  constructor(private router: Router) {
    router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(() => {
      this.enable();
    });
  }

  public disable(): void {
    this.disabled = true;
    this.update();
  }

  public enable(): void {
    this.disabled = false;
    this.update();
  }

  public show(): void {
    this.shown = true;
    this.update();
  }

  public hide(): void {
    this.shown = false;
    this.update();
  }

  private update(): void {
    this.shown$.next(this.shown && !this.disabled);
    this.disabled$.next(this.disabled);
  }
}
